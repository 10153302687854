import React, { FC, useContext, useRef } from "react";
import { graphql } from "gatsby";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  xSpaceRem,
  fontStacks,
  targetBaseFontSizePx,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { ToolboxPageQuery, BlockFragment } from "../../graphql-types";
import { Footer } from "../components/footer";
import { css } from "styled-components";
import { Blocks } from "../components/blocks";
import { SEO } from "../components/seo";
import { PersistedData } from "../components/persisted-data";
import { useForm } from "react-hook-form";

export const query = graphql`
  query ToolboxPage {
    wp {
      page(id: "toolbox/", idType: URI) {
        title
        blocks {
          ...Block
        }
        protectedPageCustomFields {
          username
          password
        }
        seo {
          ...SEO
        }
      }
    }
  }
`;

type LoginForm = {
  username: string;
  password: string;
};

const ToolboxPage: FC<{ data: ToolboxPageQuery }> = props => {
  const { remInPx, vwInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);
  const { handleSubmit, register, errors } = useForm<LoginForm>();

  return (
    <>
      <SEO {...props.data.wp.page?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div
            style={{
              ...(pageTitleRef.current &&
                (() => {
                  const paddingYRem =
                    ySpaceRem / 2 -
                    ((pageTitleRef.current.getBoundingClientRect().height /
                      remInPx) %
                      (ySpaceRem / 2));

                  return {
                    paddingTop: `${0.5 * paddingYRem}rem`,
                    paddingBottom: `${0.5 * paddingYRem}rem`,
                  };
                })()),
              transition: "padding 0.25s ease",
            }}
          >
            <h1
              ref={pageTitleRef}
              style={{
                fontFamily: fontStacks.cubano,
              }}
              css={(() => {
                const minFontSizeRem = 2;
                const maxFontSizeRem = 2.8888;
                const vwRangeStartPx = 500;

                return css`
                  font-size: ${(minFontSizeRem *
                    (remInPx || targetBaseFontSizePx)) /
                    (vwRangeStartPx / 100)}vw;
                  line-height: 1;

                  @media (max-width: ${vwRangeStartPx}px) {
                    font-size: ${minFontSizeRem}rem;
                  }

                  @media (min-width: ${vwRangeStartPx *
                      (maxFontSizeRem / minFontSizeRem)}px) {
                    font-size: ${maxFontSizeRem}rem;
                  }
                `;
              })()}
            >
              {props.data.wp.page?.title}
            </h1>
          </div>
        </Center>
      </WavySection>
      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <p>
              Voor de werkgroep communicatie en de werkgroep DPRA van de
              betrokken partners is een toolbox beschikbaar gesteld. Deze is te
              bereiken via onderstaande inlog.
            </p>

            <PersistedData>
              {({
                username,
                setUsername,
                password,
                setPassword,
                isExpired,
              }) => {
                const isLoggedIn =
                  props.data.wp.page?.protectedPageCustomFields?.username ===
                    username &&
                  props.data.wp.page?.protectedPageCustomFields?.password ===
                    password &&
                  !isExpired;

                const onSubmit = (values: LoginForm) => {
                  setUsername(values.username);
                  setPassword(values.password);
                };

                if (!isLoggedIn) {
                  return (
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      style={{ marginTop: `${0.5 * ySpaceRem}rem` }}
                    >
                      <label style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                        <p style={{ marginBottom: `${0.25 * ySpaceRem}rem` }}>
                          Gebruikersnaam:
                        </p>

                        <input
                          ref={register({
                            required:
                              "Het veld 'Gebruikersnaam' is niet gevuld",
                            validate: value => {
                              if (
                                value ===
                                props.data.wp.page?.protectedPageCustomFields
                                  ?.username
                              ) {
                                return true;
                              }

                              return "De ingevulde gebruikersnaam is niet correct";
                            },
                          })}
                          name="username"
                          autoComplete="username"
                          type="text"
                          style={{
                            marginBottom: `${0.5 * ySpaceRem}rem`,
                            height: `${1.5 * ySpaceRem}rem`,
                            borderRadius: "0.5rem",
                            border: `2px solid ${colors.black}`,
                            paddingLeft: `${0.25 * xSpaceRem}rem`,
                            paddingRight: `${0.25 * xSpaceRem}rem`,
                            fontSize: "1rem",
                            fontFamily: fontStacks.univiaPro,
                          }}
                        />

                        {errors.username && (
                          <p
                            style={{
                              marginBottom: `${ySpaceRem}rem`,
                              color: colors.orange,
                              fontWeight: 700,
                            }}
                          >
                            {errors.username.message}
                          </p>
                        )}
                      </label>

                      <label>
                        <p style={{ marginBottom: `${0.25 * ySpaceRem}rem` }}>
                          Wachtwoord:
                        </p>

                        <input
                          ref={register({
                            required: "Het veld 'Wachtwoord' is niet gevuld",
                            validate: value => {
                              if (
                                value ===
                                props.data.wp.page?.protectedPageCustomFields
                                  ?.password
                              ) {
                                return true;
                              }

                              return "Het ingevulde wachtwoord is niet correct";
                            },
                          })}
                          name="password"
                          autoComplete="current-password"
                          type="password"
                          style={{
                            marginBottom: `${0.5 * ySpaceRem}rem`,
                            height: `${1.5 * ySpaceRem}rem`,
                            borderRadius: "0.5rem",
                            border: `2px solid ${colors.black}`,
                            paddingLeft: `${0.25 * xSpaceRem}rem`,
                            paddingRight: `${0.25 * xSpaceRem}rem`,
                            fontSize: "1rem",
                            fontFamily: fontStacks.univiaPro,
                          }}
                        />

                        {errors.password && (
                          <p
                            style={{
                              marginBottom: `${1 * ySpaceRem}rem`,
                              color: colors.orange,
                              fontWeight: 700,
                            }}
                          >
                            {errors.password.message}
                          </p>
                        )}
                      </label>

                      <button
                        style={{
                          display: "block",
                          borderRadius: "1.5rem",
                          border: "none",
                          backgroundColor: colors.orange,
                          fontSize: "1rem",
                          fontWeight: 700,
                          fontFamily: fontStacks.univiaPro,
                          color: colors.white,
                          paddingTop: `${0.25 * ySpaceRem}rem`,
                          paddingBottom: `${0.25 * ySpaceRem}rem`,
                          textAlign: "center",
                          cursor: "pointer",
                          paddingLeft: `${1 * xSpaceRem}rem`,
                          paddingRight: `${1 * xSpaceRem}rem`,
                        }}
                        type="submit"
                      >
                        Inloggen
                      </button>
                    </form>
                  );
                }

                return (
                  <>
                    <div style={{ marginBottom: `${ySpaceRem}rem` }}>
                      <button
                        style={{
                          display: "block",
                          borderRadius: "1.5rem",
                          border: "none",
                          backgroundColor: colors.orange,
                          fontSize: "1rem",
                          fontWeight: 700,
                          fontFamily: fontStacks.univiaPro,
                          color: colors.white,
                          paddingTop: `${0.25 * ySpaceRem}rem`,
                          paddingBottom: `${0.25 * ySpaceRem}rem`,
                          textAlign: "center",
                          cursor: "pointer",
                          paddingLeft: `${1 * xSpaceRem}rem`,
                          paddingRight: `${1 * xSpaceRem}rem`,
                        }}
                        onClick={() => {
                          setUsername("");
                          setPassword("");
                        }}
                      >
                        Uitloggen
                      </button>
                    </div>
                    <Blocks
                      blocks={props.data.wp.page?.blocks as BlockFragment[]}
                    />
                  </>
                );
              }}
            </PersistedData>
          </div>
        </Center>
      </div>
      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default ToolboxPage;
